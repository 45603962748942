import * as React from "react";
import styled from "@emotion/styled";
import {RightOutlined} from "@ant-design/icons"
import {ReactNode} from "react";
import {Col, Row, Spin} from "antd";
import {css, cx} from "@emotion/css";

const Container = styled.div`
  padding: 0 24px 24px;
  margin: 0 auto;
  //width: ${(props: any) => props.width}px;
  min-height: calc(100vh - 252px);
  flex: 1;
  background: #ffffff;
  border-radius: 8px;
`

const Header = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
`

const Title = styled.div`
  line-height: 62px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC, sans-serif;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TitleAppend = styled.div`
  margin-left: 12px;
  line-height: 62px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.6);
`

const HeaderTools = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
`

const BackLink = styled.a`
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: #000000;
  text-decoration: none;
`

const Content = styled.div`
  padding-top: ${(props: any) => props.paddingTop}px;
  ${(props: any) => !!props.flex ? "display: flex;" : "" }
  ${(props: any) => !!props.direction ? `flex-direction:${props.direction};` : "" }
  ${(props: any) => !!props.gap ? `gap:${props.gap}px;` : "" }
`

export type RecommendBodyProps = {
  title?: string;
  desc?: string;
  append?: ReactNode;
  backLink?: string | true;
  tools?: ReactNode;
  header?: ReactNode;
  flex?: boolean;
  gap?: number;
  direction?: 'column' | 'row',
  paddingTop?: number;
  loading?: boolean;
  children?: ReactNode,
}

const ManualBody = (props: RecommendBodyProps) => {
  const {
    title = "", desc = null, append = null, backLink = null, tools = null,
    header = null, flex = false, gap = 0, direction = 'column',
    paddingTop = 24,
    loading = false,
    children,
    ...containerProps
  } = props;
  return loading && (
    <div
      className={cx(css`
        margin: 0 auto;
      `)}
    ><Spin/></div>
  ) || (<>
    <Container {...containerProps}>
      <Header>
        {header && header}
        {!header && (
          <>
            <Title>{title}</Title>
            {desc && (
              <TitleAppend>{desc}</TitleAppend>
            )}
            {append && append}
            {(tools || backLink) && (
              <HeaderTools>
                {tools}
                {backLink && typeof(backLink) == 'string' && (
                  <BackLink href={backLink}>返回 <RightOutlined style={{fontSize: 12}} /></BackLink>
                )}
                {
                  backLink == true && (
                    <BackLink onClick={() => window.history.go(-1)}>返回 <RightOutlined style={{fontSize: 12}} /></BackLink>
                  )
                }
              </HeaderTools>
            )}
          </>
        )}
      </Header>
      <Content {...{flex, gap, direction, paddingTop}}>
        {children}
      </Content>
    </Container>
  </>)
}

export default ManualBody;
