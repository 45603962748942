import * as React from "react";
import styled from "@emotion/styled";
import {RightOutlined} from "@ant-design/icons";

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Item = styled.a`
  padding: 0 16px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.04);
  }
`

const ItemTitle = styled.span`
  line-height: 24px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
`

const items = [
  {
    id: 1,
    title: '在工作过程中需求变更增加工作量怎么计算费用',
  },
  {
    id: 2,
    title: '项目交付后能不能提供持续的版本迭代服务',
  },
  {
    id: 3,
    title: '在平台开发的项目维护期有多久',
  },
]

export type ManualItem = {
  id: string | number;
  title: string;
  href: string;
}

export type ManualListProps = {
  data?: ManualItem[];
}

const ManualList = ({data}: ManualListProps) => {
  return (<>
    <Items>
      {data.map(item => (
        <Item key={item.id} href={item.href} >
          <ItemTitle>{item.title}</ItemTitle>
          <RightOutlined style={{fontSize: 12}} />
        </Item>
      ))}
    </Items>
  </>)
}

export default ManualList;
