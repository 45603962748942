import * as React from "react";
import PageWrapper from "../PageWrapper";
import styled from "@emotion/styled";
import {
  RightOutlined,
} from "@ant-design/icons"
import {ReactNode} from "react";

const Container = styled.div`
  margin: 0 auto;
  width: ${(props: any) => props.width}px;
  display: flex;
  gap: 24px;
`

const Menu = styled.div`
  padding-top: 24px;
  width: 196px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const MenuItem = styled.a`
  padding: 0 24px;
  width: 148px;
  height: 46px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC,sans-serif;
  font-weight: 500;
  color: rgba(0,0,0,0.6);
  text-decoration: none;
  & .anticon {
    color: rgba(0, 0, 0, 0.8);
  }
  &.selected, &:hover {
    color: rgb(0, 0, 0, 0.8);
    background: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
`

export type ManualLayoutMenu = {
  id: string | number;
  text: string;
  href: string;
}

export type ManualLayoutProps = {
  width?: number,
  // selected?: 'manual' | 'learn' | 'publish' | 'undertake'
  selected: string | number;
  menu?: ManualLayoutMenu[];
  loading?: boolean;
  children?: ReactNode;
}



const ManualLayout =  (props: ManualLayoutProps) => {
  const {
    width = 1000,
    selected = '',
    menu = [],
    children,
    loading = false,
  } = props
  return (<>
    <PageWrapper authorized {...{loading}}>
      <Container {...{width}}>
        <Menu>
          {menu.map(item => (
            <MenuItem href={item.href} key={item.id} className={selected == item.id ? 'selected' : ''}>
              {item.text} {selected == item.id && (<RightOutlined style={{fontSize: 10}} />)}
            </MenuItem>
          ))}
        </Menu>
        {children}
      </Container>
    </PageWrapper>
  </>)
}

export default ManualLayout
