import * as React from "react";
import ManualLayout, {ManualLayoutMenu} from "../../components/pages/manual/ManualLayout";
import ManualBody from "../../components/pages/manual/ManualBody";
import ManualList, {ManualItem} from "../../components/pages/manual/ManualList";
import Seo from "../../components/seo";
import {useEffect, useMemo, useState} from "react";
import jsonRequest from "../../utils/request/jsonRequest";
import {message} from "antd";
import {navigate} from "gatsby";

const Manual = ({location}) => {

  const [loadingMenu, setLoadingMenu] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [menu, setMenu] = useState<ManualLayoutMenu[]>([])
  const [data, setData] = useState<ManualItem[]>([])

  const selectedMenu = useMemo(() => {
    const s = location.hash.replace('#', '');
    if (menu.length > 0) {
      for (let i = menu.length - 1; i >= 0; i--) {
        if (menu[i].id == s) {
          return menu[i];
        }
      }
      // return menu[0].id;
      navigate(`#${menu[0].id}`)
    }
    return null;
  }, [location, menu])

  const loadMenu = () => {
    if (loadingMenu) {
      return;
    }
    setLoadingMenu(true)
    jsonRequest("/manual/group").then((rsp) => {
      if (rsp.success) {
        setMenu(rsp.data.data.map(item => ({
          id: item.id,
          text: item.name,
          href: `#${item.id}`
        })))
      } else {
        message.error(rsp.message)
      }
    }).catch(console.error).finally(() => {
      setLoadingMenu(false)
    })
  }

  const loadData = () => {
    if (loadingData || selectedMenu == null) {
      return;
    }
    setLoadingData(true)
    jsonRequest(`/manual?type=help&group_id=${selectedMenu.id}`).then(rsp => {
      if (rsp.success) {
        setData(rsp.data.data.map(item => ({
          id: item.id,
          title: item.title,
          href: `/manual/detail?id=${item.id}`
        })))
      } else {
        message.error(rsp.message)
      }
    }).catch(console.error).finally(() => {
      setLoadingData(false)
    })
  }

  useEffect(() => {
    loadMenu()
  }, [])

  useEffect(() => {
    loadData()
  }, [selectedMenu])

  return (<>
    <ManualLayout
      {...{selected: selectedMenu?.id ?? '', menu, loading: loadingMenu}}
    >
      <ManualBody
        title={selectedMenu?.text ?? ''}
        loading={loadingData}
      >
        <ManualList {...{data}}/>
      </ManualBody>
    </ManualLayout>
  </>)
}

export default Manual;

export const Head = () => <Seo title='GitWork-帮助中心'/>
